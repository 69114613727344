import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import ButtonTooltip from '@mui/material/Tooltip';
import { getActiveMarker, getSelectedVars, getHighlightedItem } from '../../state/selectors';
import useStyles from '../../styles';
import { VarName, varNameDetails } from '../../utils/varNames';
import { setActiveMarker } from '../../state/actions';
import { persistState, StorageTypes } from '../../utils/persistentState';
import SourcesMenu from '../../components/SourcesMenu';

function SourceToolbar(): JSX.Element {
  const classes = useStyles();
  const selectedVars = useSelector(getSelectedVars);
  const dispatch = useDispatch();
  const activeMarker = useSelector(getActiveMarker);
  const highlightedItem = useSelector(getHighlightedItem);

  const onMarkerClickHandler = (varName: VarName) => {
    dispatch(setActiveMarker(varName));
    persistState(varName, StorageTypes.ActiveMarker);
  };

  const activeVarname = useMemo(() => {
    let activeSource: VarName = activeMarker;
    if (highlightedItem.id !== '' && highlightedItem.varName)
      activeSource = highlightedItem.varName;
    return activeSource;
  }, [highlightedItem, activeMarker]);

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Box className={classes.sourceToolbar}>
        {selectedVars.map((item) => {
          const { icon, label, id } = varNameDetails[item];
          const Icon = icon;

          return Icon ? (
            <ButtonTooltip
              className={`${activeVarname === id ? classes.mapToolbarActiveButton : ''}`}
              title={label}
              placement="bottom"
              key={`sourceToolbar-${label}`}
            >
              <IconButton onClick={() => onMarkerClickHandler(item)} size="large">
                <Icon />
              </IconButton>
            </ButtonTooltip>
          ) : (
            ''
          );
        })}
        <Box sx={{ display: 'inline-flex' }}>
          <SourcesMenu iconOnlyBtn helpPosition="bottom" />
        </Box>
      </Box>
    </Box>
  );
}

export default SourceToolbar;
