import { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  getActiveMarker,
  getClickedItem,
  getCurrentLocation,
  getHighlightedItem,
  getLocationsById,
  getMotionThreshold,
  getSelectedLocationVarValues,
  getSensorsById,
  getSensorsByLocId,
} from '../../state/selectors';
import { VarName } from '../../utils/varNames';
import WeatherService from '../../services/weatherService';
import { getDataValueNumber } from '../HelperComponents/DataValueString';

// outside reading for temp/hum and avg reading for other sources
export default function useOutsideReading(): number | undefined {
  const weatherService = WeatherService.getInstance();
  const selectedVarValues = useSelector(getSelectedLocationVarValues);
  const activeMarker = useSelector(getActiveMarker);
  const sensorsById = useSelector(getSensorsById);
  const locationsById = useSelector(getLocationsById);
  const currentLocation = useSelector(getCurrentLocation);
  const highlightedItem = useSelector(getHighlightedItem);
  const clickedItem = useSelector(getClickedItem);
  const locSensors = useSelector(getSensorsByLocId).get(currentLocation);
  const motionThreshold = useSelector(getMotionThreshold);

  const [outsideWeather, setOutsideWeather] = useState<number>();

  const sensorId = (highlightedItem.id ? highlightedItem : clickedItem).id;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const activeMarkerData = selectedVarValues.get(activeMarker) ?? [];

  const showOutsideWeather =
    activeMarker === VarName.TemperatureC || activeMarker === VarName.RelativeHumidity;

  // detect location change and update outside weather
  useEffect(() => {
    if (showOutsideWeather) {
      let position;
      const locPos = locationsById.get(currentLocation)?.position;
      const sensorPos = sensorsById.get(sensorId)?.position;

      if (locPos) position = locPos;
      else if (sensorPos) position = sensorPos;
      else if (locSensors) {
        for (let i = 0; i < locSensors.length; i++) {
          const locSensorPos = sensorsById.get(locSensors[i])?.position;
          if (locSensorPos) {
            position = locSensorPos;
            break;
          }
        }
      }

      if (position && position.lat && position.lng) {
        weatherService
          .getCurrentWeather(currentLocation, position.lat, position.lng)
          .then((data) => {
            if (activeMarker === VarName.TemperatureC && data.main?.temp)
              setOutsideWeather(Math.round(data.main?.temp));
            else if (activeMarker === VarName.RelativeHumidity && data.main?.humidity)
              setOutsideWeather(Math.round(data.main?.humidity));
          });
      } else setOutsideWeather(undefined);
    }
  }, [
    activeMarker,
    currentLocation,
    locSensors,
    locationsById,
    sensorId,
    sensorsById,
    showOutsideWeather,
    weatherService,
  ]);

  const avgReading = useMemo(() => {
    let avgValue;
    if (showOutsideWeather) {
      if (outsideWeather) avgValue = outsideWeather;
    } else {
      avgValue = Math.trunc(
        activeMarkerData.reduce(
          (acc, curr) => acc + (getDataValueNumber(curr, motionThreshold) ?? 0),
          0
        ) / activeMarkerData.length
      );
    }
    return avgValue;
  }, [activeMarkerData, motionThreshold, outsideWeather, showOutsideWeather]);

  return avgReading;
}
