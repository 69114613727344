import React from 'react';
import { useTheme } from '@mui/material/styles';
import { useSelector, useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import SourcesMenu from './SourcesMenu';
import { VarName, varNameDetails } from '../utils/varNames';
import SensorIcon from '../styles/icons/SensorIcon';
import {
  getActiveMarker,
  getClickedItem,
  getHighlightedItem,
  getSelectedVars,
} from '../state/selectors';
import { setActiveMarker, setClickedItem } from '../state/actions';
import { themeProps } from '../styles/theme';
import { StorageTypes, persistState } from '../utils/persistentState';

function SelectedVarsIconBtn(): JSX.Element {
  const theme = useTheme();
  const dispatch = useDispatch();
  const selectedVars = useSelector(getSelectedVars);
  const activeMarker = useSelector(getActiveMarker);
  const clickedItem = useSelector(getClickedItem);
  const highlightedItem = useSelector(getHighlightedItem);
  const activeItem = highlightedItem.id ? highlightedItem : clickedItem;
  const selectedVarName = activeItem.varName ?? activeMarker;

  const onClickHandler = (varName: VarName) => {
    dispatch(setClickedItem({ id: '' }));
    dispatch(setActiveMarker(varName));
    persistState(varName, StorageTypes.ActiveMarker);
  };

  return (
    <Box sx={{ display: 'flex', overflowX: 'scroll' }}>
      {selectedVars.map((varName) => {
        const SourceIcon = varNameDetails[varName]?.icon ?? SensorIcon;
        return (
          <Box sx={{ margin: '0 5px', alignSelf: 'center' }} key={varName}>
            <Tooltip title={varNameDetails[varName].label}>
              <IconButton
                onClick={() => onClickHandler(varName)}
                style={{
                  background:
                    selectedVarName === varName
                      ? `${themeProps.colors.green}66`
                      : theme.palette.primary.light,
                }}
              >
                <SourceIcon />
              </IconButton>
            </Tooltip>
          </Box>
        );
      })}
      <Box sx={{ margin: '0 5px' }}>
        <SourcesMenu iconOnlyBtn />
      </Box>
    </Box>
  );
}

export default SelectedVarsIconBtn;
