import React, { useMemo } from 'react';
import { useTheme } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { PlotRelayoutEvent } from 'plotly.js';
import Plot from './PlotlyCustom';
import { VarName, varNameDetails } from '../../utils/varNames';
import { getDataBandParams } from '../../utils/dataBandParams';
import {
  SensorHistoryPlotItem,
  tickformatstops,
  transformSimplePositiveDerivative,
} from './plotCommon';
import { themeProps } from '../../styles/theme';

interface PlotProps {
  data: SensorHistoryPlotItem;
  inlinePlot?: boolean; // used for integrating small line graph in list items or table
  transparentBg?: boolean;
  staticPlot?: boolean;
  handleRelayout?: (event: PlotRelayoutEvent) => void;
  xRange?: [Date, Date];
}

export function ColouredLinePlot({
  data,
  inlinePlot,
  transparentBg,
  staticPlot,
  handleRelayout,
  xRange,
}: PlotProps): JSX.Element {
  const theme = useTheme();
  const { sensorName, varName } = { ...data };
  let { history } = { ...data };
  const { suggestedScaleValues } = { ...varNameDetails[varName] };
  let { label, metric } = { ...varNameDetails[varName] };

  // for inlinePlot, it's always static with transparent background
  const isTransparentBg = transparentBg === undefined ? !!inlinePlot : transparentBg;
  const isStaticPlot = staticPlot === undefined ? !!inlinePlot : staticPlot;

  // Transform energy meter history data to simple average power for easier viewing
  if (varName === VarName.EnergyInkWh) {
    label = 'Average Power';
    metric = 'kW';
    history = transformSimplePositiveDerivative(history);
  }

  const [xData, yData, markerColour] = useMemo(() => {
    // If there is no data add some transparent points to preserve the desired display range
    if (!history?.time?.length && xRange) {
      const x = xRange;
      const y = [0, 0];
      const colour = 'rgba(0, 0, 0, 0)';
      return [x, y, colour];
    }
    const x = history?.time?.map((i: number) => new Date(i * 1000));
    const y = history?.value;
    const colour = history.value.map((i: number) => getDataBandParams(varName, i).color);

    return [x, y, colour];
  }, [history, varName, xRange]);

  const fillUnderPlot =
    data.varName === VarName.ClientsWiFi ||
    data.varName === VarName.ClientsBle ||
    data.varName === VarName.EnergyInkWh;

  const plotData = {
    mode: fillUnderPlot ? 'lines' : 'lines+markers',
    x: xData,
    y: yData,
    marker: {
      color: markerColour,
      size: 5,
    },
    line: {
      color: fillUnderPlot ? markerColour : 'grey',
      width: 1,
      shape: fillUnderPlot ? 'hv' : 'linear',
    },
    name: `${sensorName} - ${label} ${metric || ''}`,
    hovertemplate: `%{y} ${metric || ''} - %{x} <br> ${sensorName} <extra></extra>`,
    fill: fillUnderPlot ? 'tozeroy' : null,
  } as Plotly.Data;

  const minVal = Math.min(...history.value);
  const maxVal = Math.max(...history.value);

  const getRange = (scaleValues: [number, number] | undefined) => {
    if (scaleValues)
      return [
        Math.min(scaleValues[0], minVal - (maxVal - minVal) * 0.05),
        Math.max(scaleValues[1], maxVal + (maxVal - minVal) * 0.05),
      ];
    return [minVal - (maxVal - minVal) * 0.05, maxVal + (maxVal - minVal) * 0.05];
  };

  const range = getRange(suggestedScaleValues);

  return (
    <Plot
      data={[plotData]}
      layout={{
        font: { family: themeProps.fontFamily.body },
        width: inlinePlot ? 200 : undefined,
        height: inlinePlot ? 40 : 200,
        autosize: true,
        // Set margins to maximise plot area (need to use automargin on axes)
        margin: {
          l: 5,
          r: 5,
          b: 5,
          t: 5,
          pad: 0,
        },
        showlegend: false,
        dragmode: 'pan',
        xaxis: {
          automargin: true,
          range: xRange || [
            new Date(Math.min(...history.time) * 1000),
            new Date(Math.max(...history.time) * 1000),
          ],
          tickformatstops,
          // nticks: 12,  // Allow more ticks than default
          showticklabels: !inlinePlot,
          showgrid: !inlinePlot,
          showline: !inlinePlot,
          linecolor: theme.palette.text.primary,
          tickfont: {
            color: theme.palette.text.primary,
          },
          gridcolor: alpha(theme.palette.text.primary, 0.1),
        },
        yaxis: {
          automargin: true,
          title: inlinePlot ? '' : `${label} ${metric ? `(${metric})` : ''}`,
          range,
          fixedrange: true, // Disable pan/zoom
          showticklabels: !inlinePlot,
          showgrid: !inlinePlot,
          showline: !inlinePlot,
          tickfont: {
            color: theme.palette.text.primary,
          },
          titlefont: {
            color: theme.palette.text.primary,
          },
          gridcolor: alpha(theme.palette.text.primary, 0.1),
        },
        plot_bgcolor: isTransparentBg ? 'rgba(0, 0, 0, 0)' : theme.palette.primary.main,
        paper_bgcolor: isTransparentBg ? 'rgba(0, 0, 0, 0)' : theme.palette.primary.main,
      }}
      config={{ displayModeBar: false, staticPlot: isStaticPlot }}
      useResizeHandler
      onRelayout={handleRelayout ? (e) => handleRelayout(e) : undefined}
      style={{ width: '100%', height: '100%' }}
    />
  );
}

ColouredLinePlot.defaultProps = {
  inlinePlot: false,
  transparentBg: undefined,
  staticPlot: undefined,
  handleRelayout: undefined,
  xRange: undefined,
};

export default ColouredLinePlot;
