import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import {
  GatewayDataType,
  GatewayVisibleSubsensors,
  getAllowedGateways,
} from '../SensorConfig/helpers';
import { getAllLocationFloorplan, getCurrentLocation, getSensorsById } from '../../state/selectors';
import { SensorLatest } from '../../services/api';
import { appSwitchBtnHandler } from '../../Shell/helpers';
import { fetchSensorLatest } from '../../services/apiService';
import { themeProps } from '../../styles/theme';
import RSSILookup from '../SensorConfig/RSSILookup';

import LocationSwitcher from '../../components/LocationSwitcher';
import PositioningMap from '../Map/PositioningMap';
import { AlertMsg } from '../LocationConfig/LocationProperties';
import { setSensorsById } from '../../state/actions';

interface LocateContainerProps {
  selectedSensorId: string;
  gatewayDetails: SensorLatest | undefined;
  setGatewayDetails: (value: SensorLatest) => void;
  gatewayVisibleSubsensors: GatewayVisibleSubsensors[] | undefined;
  handleNext: () => void;
}

const defaultAlert: AlertMsg = {
  success: false,
  msg: 'Confirm your install location',
  alertType: 'info',
};

function LocateContainer({
  selectedSensorId,
  gatewayDetails,
  setGatewayDetails,
  gatewayVisibleSubsensors,
  handleNext,
}: LocateContainerProps): JSX.Element {
  const dispatch = useDispatch();
  const theme = useTheme();
  const currentLocation = useSelector(getCurrentLocation);
  const sensorsById = useSelector(getSensorsById);
  const allFloorPlan = useSelector(getAllLocationFloorplan);
  const [allowedGateways, setAllowedGateways] = useState<GatewayDataType[]>([]);
  const [selectedGatewayId, setSelectedGatewayId] = useState<string>();
  const [alertMsg, setAlertMsg] = useState<AlertMsg>(defaultAlert);

  // get all possible gateways for the sensor from that location
  useEffect(
    () => {
      setAllowedGateways([]);
      setSelectedGatewayId(undefined);
      if (gatewayVisibleSubsensors && selectedSensorId) {
        getAllowedGateways(gatewayVisibleSubsensors, selectedSensorId)
          .then((res) => {
            setAllowedGateways(res);
          })
          .catch(() => null);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [gatewayVisibleSubsensors, selectedSensorId, currentLocation]
  );

  // set initial default gateway
  useEffect(() => {
    if (!selectedGatewayId && allowedGateways.length > 0)
      setSelectedGatewayId(allowedGateways[0].gatewayId);
  }, [allowedGateways, selectedGatewayId]);

  // fetch gateway details
  useEffect(() => {
    if (selectedGatewayId) {
      setAlertMsg(defaultAlert);
      const sensorDetails = sensorsById.get(selectedGatewayId);
      if (sensorDetails) setGatewayDetails(sensorDetails);
      else
        fetchSensorLatest(selectedGatewayId).then((details) => {
          setGatewayDetails(details);
          dispatch(setSensorsById([details]));
        });
    } else setGatewayDetails({ id: '' });
  }, [dispatch, selectedGatewayId, sensorsById, setGatewayDetails]);

  const btnClickHandler = () => {
    setAlertMsg(defaultAlert);
    if (selectedGatewayId) handleNext();
    else
      setAlertMsg({
        success: false,
        msg: 'Gateway or visible subsensor info not found',
        alertType: 'error',
      });
  };

  return (
    <div style={{ textAlign: 'center' }}>
      {alertMsg && <Alert severity={alertMsg.alertType}>{alertMsg.msg}</Alert>}
      <Box
        sx={{
          alignSelf: 'center',
          width: '100%',
          overflowX: 'scroll',
          padding: '5px 0',
        }}
      >
        <LocationSwitcher />
      </Box>
      {/* {allowedGateways.length > 0 && ( */}
      <Box sx={{ width: '100%', margin: 'auto', padding: '10px' }}>
        <InputLabel
          id="gateway-id"
          style={{
            textAlign: 'left',
            fontSize: themeProps.textSize.small,
          }}
        >
          Selected Gateway*
        </InputLabel>
        <Select
          label="gateway"
          labelId="gateway-id"
          value={selectedGatewayId ?? ''}
          onChange={(e) => setSelectedGatewayId(e.target.value)}
          variant="standard"
          style={{ width: '100%', textAlign: 'left', padding: '10px', height: '40px' }}
        >
          {allowedGateways.map((gateway) => (
            <MenuItem value={gateway.gatewayId} key={gateway.gatewayId}>
              <Box sx={{ display: 'flex' }}>
                <Box sx={{ alignSelf: 'center' }}>
                  <RSSILookup RSSI={gateway.RSSI} />
                </Box>
                <Box sx={{ alignSelf: 'center', marginLeft: '5px' }}>
                  {sensorsById.get(gateway.gatewayId)?.name ?? gateway.gatewayId}
                </Box>
              </Box>
            </MenuItem>
          ))}
        </Select>
        <PositioningMap
          floorPlan={allFloorPlan.get(currentLocation)}
          onChange={() => null}
          position={undefined}
          mapName="SensorCreate"
          gatewayPosition={gatewayDetails?.position}
        />
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'center', margin: '10px 0' }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => appSwitchBtnHandler(dispatch)}
          startIcon={<KeyboardDoubleArrowLeftIcon />}
          style={{ margin: '0 10px' }}
        >
          Sensors List
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={btnClickHandler}
          endIcon={<KeyboardDoubleArrowRightIcon />}
          style={{
            margin: '0 10px',
            color: !gatewayDetails?.id ? themeProps.colors.lightGrey : theme.palette.text.secondary,
          }}
        >
          Next
        </Button>
      </Box>
    </div>
  );
}

export default LocateContainer;
