import React from 'react';
import { useTheme } from '@mui/material/styles';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function LFSwitchLogoIcon(props: SvgIconProps): JSX.Element {
  const theme = useTheme();

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon viewBox="0 0 46 46" {...props}>
      <circle cx="23" cy="23" r="23" fill="#10CDAE" />
      <path
        d="M26.0786 22.7928C26.0786 21.0357 24.6572 19.6143 22.9 19.6143C21.1429 19.6143 19.7214 21.0357 19.7214 22.7928C19.7214 24.55 21.1429 25.9714 22.9 25.9714C24.6572 25.9714 26.0786 24.55 26.0786 22.7928Z"
        fill={theme.palette.primary.dark}
      />
      <path
        d="M31.4643 24.7928C30.2286 24.7928 29.2286 23.7928 29.2286 22.5571C29.2286 19.1785 26.4786 16.4214 23.0929 16.4214C21.8572 16.4214 20.8572 15.4214 20.8572 14.1857C20.8572 12.95 21.8572 11.95 23.0929 11.95C28.9357 11.95 33.6929 16.7071 33.6929 22.55C33.6929 23.7857 32.6929 24.7857 31.4572 24.7857L31.4643 24.7928Z"
        fill={theme.palette.primary.dark}
      />
      <path
        d="M22.8999 42.0786C12.3214 42.0786 3.71423 33.4715 3.71423 22.9001C3.71423 12.3286 12.3214 3.71436 22.8999 3.71436C33.4785 3.71436 42.0857 12.3215 42.0857 22.9001C42.0857 24.1358 41.0857 25.1358 39.8499 25.1358C38.6142 25.1358 37.6142 24.1358 37.6142 22.9001C37.6142 14.7858 31.0142 8.18578 22.8999 8.18578C14.7857 8.18578 8.17852 14.7786 8.17852 22.9001C8.17852 31.0215 14.7785 37.6144 22.8928 37.6144C24.1285 37.6144 25.1285 38.6144 25.1285 39.8501C25.1285 41.0858 24.1285 42.0858 22.8928 42.0858L22.8999 42.0786Z"
        fill={theme.palette.primary.dark}
      />
    </SvgIcon>
  );
}

export default LFSwitchLogoIcon;
