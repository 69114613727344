import React, { useState, useMemo } from 'react';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import posthog from 'posthog-js';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { useTheme } from '@mui/material/styles';

import { getActiveMarker, getLocationsById, getSensorsById } from '../../../state/selectors';
import {
  BandParamsType,
  getDataBandParams,
  varNameBandParams,
} from '../../../utils/dataBandParams';
import { themeProps } from '../../../styles/theme';
import { varNameDetails } from '../../../utils/varNames';

interface FeedbackProps {
  sensorId: string;
  sensorValue: number;
  reset: () => void;
}

function HrefLink({ url, label }: { url: string; label: string }) {
  const theme = useTheme();

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <Link
      to="#"
      onClick={(e) => {
        window.location.href = url;
        e.preventDefault();
      }}
      style={{ color: theme.palette.text.primary }}
    >
      {label}
    </Link>
  );
}

function Feedback({ sensorId, sensorValue, reset }: FeedbackProps) {
  const theme = useTheme();
  const activeMarker = useSelector(getActiveMarker);
  const sensorsById = useSelector(getSensorsById);
  const locationsById = useSelector(getLocationsById);
  const bands = varNameBandParams[activeMarker];
  const { label, color } = getDataBandParams(activeMarker, sensorValue);
  const [selectedBand, setSelectedBand] = useState<BandParamsType>();
  const [showDialog, setShowDialog] = useState<boolean>(false);

  const sensorDetails = sensorsById.get(sensorId) ?? { id: sensorId };

  const { location, position, name } = sensorDetails;

  const locFullName = useMemo(() => {
    let loc = location;
    const propLocItem = locationsById.get(location ?? '');
    const locName = propLocItem?.name;
    if (location && location === '#') loc = locName;
    else if (location && location.startsWith('#')) {
      const parentLocName = locationsById.get(propLocItem?.location ?? '')?.name;
      loc = `${parentLocName}, ${locName}`;
    }

    return loc;
  }, [location, locationsById]);

  const handleSubmit = () => {
    setShowDialog(true);
    posthog.capture('App Feedback', {
      sensorId,
      sensorName: name,
      sensorPos: position,
      sensorLoc: locFullName ?? sensorDetails?.location, // set full location e.g. 5 Endeavour Square, 10th Floor
      locationId: sensorDetails?.location,
      varName: activeMarker,
      value: sensorValue,
      feedBackSelection: selectedBand?.label,
    });
  };

  const emailSubject = `${varNameDetails[activeMarker].label} ${name} ${selectedBand?.label}`;

  // %0D%0A refers to line break
  const emailBodyContent = `Dear Helpdesk, %0D%0A%0D%0A [please enter your issue] %0D%0A%0D%0A My Location: ${locFullName}, ${name}.`;

  return (
    <Box>
      <Dialog
        open={showDialog}
        style={{ textAlign: 'center' }}
        onClose={() => setShowDialog(false)}
      >
        <DialogContent>
          Thank you for your feedback. This will be used to help improve building operations in the
          long term. <br /> <br /> To report a temperature concern please contact TfL’s facilities
          help desk directly via email{' '}
          <HrefLink
            label="Facilities.HelpDesk@tube.tfl.gov.uk"
            url={`mailto:Facilities.HelpDesk@tube.tfl.gov.uk?subject=${emailSubject}&body=${emailBodyContent}`}
          />{' '}
          or phone <HrefLink label="02071261999" url="tel:02071261999" /> (select option 1). <br />
          <br />
          If you would like to share feedback about the app itself please contact the LightFi team
          via email <HrefLink label="support@lightfi.io" url="mailto:support@lightfi.io}" />
          .
          <br /> <br /> <b>Detail:</b>
          <br /> You are currently near <b>{name}</b> at{' '}
          <b>{dayjs().format('MMM DD, YYYY HH:mm')}</b> and feel the{' '}
          {varNameDetails[activeMarker].label} is <b>{selectedBand?.label}</b>
          <br />
          <Button
            variant={themeProps.btnVariant.default}
            sx={{
              margin: '10px',
              width: '50%',
              background: themeProps.colors.warningRed,
              border: 'none',
            }}
            onClick={() => setShowDialog(false)}
          >
            Close
          </Button>
        </DialogContent>
      </Dialog>

      <Typography variant="h4" sx={{ textAlign: 'center' }}>
        {name ?? sensorId}
      </Typography>
      <Typography variant="h6" sx={{ textAlign: 'center', marginTop: '2rem' }}>
        {varNameDetails[activeMarker].label} is currently {sensorValue}{' '}
        {varNameDetails[activeMarker].metric} which is meant to be{' '}
        <span style={{ color }}>{label}</span>
      </Typography>
      <hr style={{ width: '50%', marginTop: '1rem' }} />
      <Typography variant="h4" sx={{ textAlign: 'center', marginTop: '2rem' }}>
        I feel the {varNameDetails[activeMarker].label} is:
      </Typography>
      <Box sx={{ marginTop: '2rem' }}>
        {bands &&
          bands.map((band) => (
            <Button
              variant={themeProps.btnVariant.default}
              sx={{
                marginTop: '1rem',
                borderRadius: '20px',
                background:
                  band === selectedBand ? themeProps.colors.green : theme.palette.primary.light,
                border: 'none',
                width: '100%',
                padding: '10px 0',
              }}
              onClick={() => setSelectedBand(band)}
            >
              {band.label}
            </Button>
          ))}

        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>
          <Button
            variant={themeProps.btnVariant.default}
            sx={{
              margin: '10px',
              width: '50%',
              background: themeProps.colors.happyGreen,
              border: 'none',
              padding: '15px',
            }}
            onClick={handleSubmit}
            disabled={!selectedBand}
          >
            Submit
          </Button>
          <Button
            variant={themeProps.btnVariant.default}
            sx={{
              margin: '10px',
              width: '50%',
              background: themeProps.colors.warningRed,
              border: 'none',
            }}
            onClick={reset}
          >
            Back
          </Button>
        </Box>
        <span>
          Submitting feedback is anonymous. We do not capture any personal data from you or your
          device.
        </span>
      </Box>
    </Box>
  );
}

export default Feedback;
