import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import {
  useContextDatePickerOffsetPropGetters,
  useContextCalendars,
  useContextDays,
} from '@rehookify/datepicker';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import TextField from '@mui/material/TextField';
import { setSelectedEndDate, setSelectedStartDate } from '../../state/actions';

export enum ShortCutType {
  today = 'today',
  pastDays = 'pastDays', // last number of days
  pastWeeks = 'pastWeeks', // last number of weeks
  pastMonths = 'pastMonths', // last number of months
  lastMonth = 'lastMonth', // prev month based on selected month
  thisMonth = 'thisMonth', // selected month
  nextMonth = 'nextMonth', // next month based on selected month
}

interface ShortcutMenuProps {
  resetPickerType: () => void; // reset to days view if in month
}

function ShortcutMenu({ resetPickerType }: ShortcutMenuProps) {
  const dispatch = useDispatch();
  const { addOffset, subtractOffset } = useContextDatePickerOffsetPropGetters();
  const { calendars } = useContextCalendars();
  const [start, end] = useContextDays().selectedDates;

  const [shortCutType, setShortCutType] = useState<ShortCutType>();
  const [daysCount, setDaysCount] = useState(7);
  const [weeksCount, setWeeksCount] = useState(4);
  const [monthsCount, setMonthsCount] = useState(6);
  const [selectedMonthRef, setSelectedMonthRef] = useState(dayjs());

  const today = new Date();

  // relocate calendar based on selected date
  const handleCalendarUpdate = (targetDate: Date) => {
    const currentDisplayDate = calendars[0].days.find(
      (item) => item.inCurrentMonth === true
    )?.$date;
    const currentDisplay = dayjs(currentDisplayDate).format('MMMM YYYY'); // currently displayed month on calendar
    const targetDisplay = dayjs(targetDate).format('MMMM YYYY');
    const monthDiff = dayjs(targetDate).diff(dayjs(currentDisplayDate), 'month');

    if (monthDiff > 0) {
      const thisMonth = dayjs().format('MMMM YYYY');
      const addMonths = { ...addOffset({ months: monthDiff }) };

      if (targetDisplay === thisMonth) {
        if (currentDisplay !== thisMonth) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          addMonths?.onClick();
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
      } else addMonths?.onClick();
    } else if (monthDiff < 0) {
      const substractMonths = { ...subtractOffset({ months: Math.abs(monthDiff) }) };
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      substractMonths?.onClick();
    }
  };

  useEffect(() => {
    if (start && end) {
      handleCalendarUpdate(end);
      setSelectedMonthRef(dayjs(end));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start, end]);

  // past days/week/month calculation based on textfield change
  useEffect(() => {
    resetPickerType();
    if (shortCutType === ShortCutType.pastDays) {
      dispatch(
        setSelectedStartDate(
          dayjs()
            .subtract(daysCount - 1, 'days')
            .startOf('D')
            .toDate()
        )
      );
      dispatch(setSelectedEndDate(new Date(today)));
    } else if (shortCutType === ShortCutType.pastWeeks) {
      dispatch(
        setSelectedStartDate(
          dayjs()
            .subtract(weeksCount * 7 - 1, 'days')
            .startOf('D')
            .toDate()
        )
      );
      dispatch(setSelectedEndDate(new Date(today)));
    } else if (shortCutType === ShortCutType.pastMonths) {
      dispatch(
        setSelectedStartDate(
          dayjs()
            .subtract(monthsCount - 1, 'M') // include current month as well
            .startOf('M')
            .toDate()
        )
      );
      dispatch(setSelectedEndDate(new Date(today)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [monthsCount, daysCount, weeksCount, shortCutType]);

  // dynamic prev/next month handling based on selected month
  const handleJumpSelection = (rangeSelection: ShortCutType) => {
    setShortCutType(ShortCutType.thisMonth);
    resetPickerType();
    switch (rangeSelection) {
      case ShortCutType.thisMonth: {
        const isThisMonth = selectedMonthRef.format('MMMM YYYY') === dayjs().format('MMMM YYYY');
        dispatch(setSelectedStartDate(dayjs(selectedMonthRef).startOf('M').toDate()));
        if (isThisMonth) dispatch(setSelectedEndDate(new Date(today)));
        else {
          dispatch(setSelectedEndDate(dayjs(selectedMonthRef).endOf('M').toDate()));
        }
        break;
      }
      case ShortCutType.lastMonth: {
        const lastMonth = dayjs(selectedMonthRef).subtract(1, 'M');
        setSelectedMonthRef(lastMonth);
        dispatch(setSelectedStartDate(dayjs(lastMonth).startOf('M').toDate()));
        dispatch(setSelectedEndDate(dayjs(lastMonth).endOf('M').toDate()));
        break;
      }
      case ShortCutType.nextMonth: {
        const nextMonth = dayjs(selectedMonthRef).add(1, 'M');
        setSelectedMonthRef(nextMonth);
        dispatch(setSelectedStartDate(dayjs(nextMonth).startOf('M').toDate()));
        if (nextMonth.format('MMMM YYYY') === dayjs().format('MMMM YYYY'))
          dispatch(setSelectedEndDate(new Date(today)));
        else {
          dispatch(setSelectedEndDate(dayjs(nextMonth).endOf('M').toDate()));
        }
        break;
      }
      default: {
        break;
      }
    }
  };

  return (
    <MenuList sx={{ padding: 0, width: '11rem' }}>
      <MenuItem
        onClick={() => {
          setShortCutType(ShortCutType.today);
          dispatch(setSelectedStartDate(dayjs().startOf('d').toDate()));
          dispatch(setSelectedEndDate(new Date(Date.now())));
        }}
        selected={shortCutType === ShortCutType.today}
      >
        Today
      </MenuItem>
      {/* Last no. of days */}
      <MenuItem
        onClick={() => setShortCutType(ShortCutType.pastDays)}
        selected={shortCutType === ShortCutType.pastDays}
      >
        Last{' '}
        {shortCutType === ShortCutType.pastDays ? (
          <TextField
            type="number"
            value={daysCount}
            onChange={(e) => {
              setDaysCount(Number(e.target.value));
            }}
            variant="standard"
            size="small"
            sx={{ width: '40px', margin: '0 10px' }}
            InputProps={{
              inputProps: {
                max: 100,
                min: 1,
              },
            }}
          />
        ) : (
          daysCount
        )}{' '}
        Day{daysCount > 1 ? 's' : ''}
      </MenuItem>

      {/* Last no. of weeks */}
      <MenuItem
        onClick={() => setShortCutType(ShortCutType.pastWeeks)}
        selected={shortCutType === ShortCutType.pastWeeks}
        sx={{ display: 'flex' }}
      >
        Last{' '}
        {shortCutType === ShortCutType.pastWeeks ? (
          <TextField
            type="number"
            value={weeksCount}
            onChange={(e) => {
              setWeeksCount(Number(e.target.value));
            }}
            variant="standard"
            size="small"
            sx={{ width: '40px', margin: '0 10px' }}
            InputProps={{
              inputProps: {
                max: 20,
                min: 1,
              },
            }}
          />
        ) : (
          weeksCount
        )}{' '}
        Week{weeksCount > 1 ? 's' : ''}
      </MenuItem>

      {/* Last no. of months */}
      <MenuItem
        onClick={() => setShortCutType(ShortCutType.pastMonths)}
        selected={shortCutType === ShortCutType.pastMonths}
      >
        Last{' '}
        {shortCutType === ShortCutType.pastMonths ? (
          <TextField
            type="number"
            value={monthsCount}
            onChange={(e) => setMonthsCount(Number(e.target.value))}
            variant="standard"
            size="small"
            sx={{ width: '40px', margin: '0 10px' }}
            InputProps={{
              inputProps: {
                max: 20,
                min: 1,
              },
            }}
          />
        ) : (
          monthsCount
        )}{' '}
        Month{monthsCount > 1 ? 's' : ''}
      </MenuItem>
      {/* possible previous month based on selected month */}
      <MenuItem onClick={() => handleJumpSelection(ShortCutType.lastMonth)}>
        {dayjs(selectedMonthRef).subtract(1, 'M').format('MMMM YYYY')}
      </MenuItem>
      {/* selected month, current month by default */}
      <MenuItem
        onClick={() => handleJumpSelection(ShortCutType.thisMonth)}
        selected={shortCutType === ShortCutType.thisMonth}
      >
        {selectedMonthRef.format('MMMM YYYY')}
      </MenuItem>
      {/* possible next month based on selected month */}
      {selectedMonthRef.format('MMMM YYYY') !== dayjs().format('MMMM YYYY') && (
        <MenuItem onClick={() => handleJumpSelection(ShortCutType.nextMonth)}>
          {dayjs(selectedMonthRef).add(1, 'M').format('MMMM YYYY')}
        </MenuItem>
      )}
    </MenuList>
  );
}

export default ShortcutMenu;
