/* eslint-disable @typescript-eslint/no-explicit-any */
import { IconLayer, IconLayerProps } from '@deck.gl/layers';
import { FLOORPLAN_ZOOM, ICON_ATLAS, ICON_MAPPING, getCentreOfObject } from '../mapHelpers';
import { GeoJSON } from '../../../services/api';

const getLabelLayer = (
  floorPlan: GeoJSON | undefined,
  mapStateZoom: number
): IconLayer<unknown, IconLayerProps<unknown>> | null => {
  let hasFloorPlan = false;
  if (floorPlan) {
    const { features } = floorPlan;
    if (features?.length > 0) hasFloorPlan = true;
  }
  if (floorPlan && hasFloorPlan && mapStateZoom >= FLOORPLAN_ZOOM) {
    const labelData = floorPlan.features.filter(({ geometry, properties }) => {
      const coordinates = geometry?.coordinates;
      const polygonType = properties?.type;
      if (coordinates) {
        switch (polygonType) {
          case 'area.toilet.m':
          case 'area.toilet.w':
          case 'area.toilet.d':
          case 'area.toilet':
          case 'area.lift':
          case 'area.stairs':
            return true;
          default:
            return false;
        }
      }
      return false;
    });
    if (labelData.length > 0) {
      return new IconLayer({
        id: 'label-layer',
        data: labelData,
        pickable: false,
        // iconAtlas and iconMapping are required
        // getIcon: return a string
        iconAtlas: ICON_ATLAS,
        iconMapping: ICON_MAPPING,
        getIcon: (d: any) => {
          switch (d?.properties?.type) {
            case 'area.toilet.m':
              return 'toilet.m';
            case 'area.toilet.w':
              return 'toilet.f';
            case 'area.toilet.d':
              return 'toilet.d';
            case 'area.toilet':
              return 'toilet';
            case 'area.lift':
              return 'lift';
            case 'area.stairs':
              return 'stairs';
            default:
              return 'unknown';
          }
        },
        sizeScale: 3.5,
        getPosition: ({ geometry }: any) => getCentreOfObject(geometry),
        getSize: (d: any) => {
          switch (d?.properties?.type) {
            case 'area.toilet.m':
            case 'area.toilet.w':
            case 'area.toilet.d':
            case 'area.lift':
            case 'area.stairs':
            default:
              return 5;
          }
        },
        getColor: () => [255, 255, 255, 175],
      });
    }
  }

  return null;
};

export default getLabelLayer;
