import React from 'react';
import { BandParamsType, varNameBandParams } from '../utils/dataBandParams';
import { VarName, varNameDetails } from '../utils/varNames';

export const bandStyles = {
  bandIndicator: {
    borderRadius: '100%',
    backgroundColor: '#555',
    width: '1ch',
    height: '1ch',
    marginRight: '0.5rem',
    display: 'inline-block',
  },
  listStyle: {
    listStyle: 'none',
    padding: '0 0 0 1rem',
  },

  listItemStyle: {
    marginBottom: '0.5rem',
  },
};

interface BandParamProps {
  varName: VarName;
}

// Make a string representation of the range the band covers
export const bandRangeStr = (bandParams: BandParamsType[]) => {
  const rangeRecord = new Map();
  for (let index = 0; index < bandParams.length; index++) {
    const band = bandParams[index];
    if (index < 1) {
      rangeRecord.set(band.label, `<${band.upperBound}`);
    } else if (index === bandParams.length - 1) {
      rangeRecord.set(band.label, `${bandParams[index - 1].upperBound}+`);
    } else {
      rangeRecord.set(band.label, `${bandParams[index - 1].upperBound}-${band.upperBound}`);
    }
  }
  return rangeRecord;
};

export function BandParamDescription({ varName }: BandParamProps): JSX.Element | null {
  const varDetails = varNameDetails[varName];
  const bandParams = varNameBandParams[varName];
  if (!bandParams) return null;

  return (
    <>
      <p>
        To analyse the data we split the {varDetails.label} levels (measured in {varDetails.metric})
        into {bandParams.length} bands:
      </p>
      <ul style={bandStyles.listStyle}>
        {bandParams.map((band) => (
          <li style={bandStyles.listItemStyle} key={band.label}>
            <div
              style={{
                ...bandStyles.bandIndicator,
                backgroundColor: band.color,
              }}
            />
            {band.label} ({bandRangeStr(bandParams).get(band.label)} {varDetails.metric}){' '}
            {band.description && `- ${band.description}`}
          </li>
        ))}
      </ul>
    </>
  );
}

type Footnote = {
  id: number;
  url?: string;
  text?: string;
};

type Descriptions = {
  description: React.ReactNode;
  footnotes?: Footnote[];
};

const varNameDescriptions: Record<VarName, Descriptions> = {
  BACnetReads: {
    description: (
      <p>
        The number of BACnet queries received by the sensors can indicate if the Building Management
        System (BMS) is functioning correctly.
      </p>
    ),
  },
  batteryLevelPct: {
    description: <p>The reported battery level of battery powered sensors</p>,
  },
  CO2ppm: {
    description: (
      <p>
        The primary source of carbon dioxide (CO₂) indoors is usually due to people breathing. High
        indoor CO₂ levels have been shown to lead to: reduced cognitive function and productivity
        <sup>[1]</sup> (presenteeism), higher rates of absenteeism
        <sup>[2]</sup> and an increased risk of viral infection transmission<sup>[3]</sup>. Building
        standard bodies such as CIBSE, RHEVA and ASHRAE advocate the use of CO₂ levels as a proxy
        measure for the risk of infection: higher CO₂ levels indicate higher risk of infection.
        Continuous measurements of CO₂ levels are an effective method of identifying poor
        ventilation and the associated risks. The levels of CO₂ measured will depend on occupancy,
        usage and ventilation, UK government (SAGE) advice is that multi-occupant spaces that are
        used regularly and reach levels above 1500 ppm should be identified and prioritised for
        improvement.
      </p>
    ),
    footnotes: [
      {
        id: 1,
        url: 'https://dash.harvard.edu/bitstream/handle/1/27662232/4892924.pdf',
        text: 'Associations of Cognitive Function Scores with Carbon Dioxide',
      },
      {
        id: 2,
        url: 'https://pubmed.ncbi.nlm.nih.gov/11089326/',
        text: 'Risk of sick leave associated with outdoor air supply rate',
      },
      {
        id: 3,
        url: 'https://doi.org/10.1111/ina.12639',
        text: 'Effect of ventilation improvement during a tuberculosis outbreak in underventilated university buildings',
      },
    ],
  },
  clientsBLE: {
    description: (
      <p>
        By measuring the aggregate number of Bluetooth devices seen in the broad vicinity of the
        Base sensors we can infer the occupancy level of the space.
      </p>
    ),
  },
  clientsWiFi: {
    description: (
      <p>
        By measuring the aggregate number of WiFi devices seen in the broad vicinity of the Base
        sensors we can infer the occupancy level of the space.
      </p>
    ),
  },
  energyInkWh: {
    description: (
      <p>
        Measured energy (positive direction). Cumulative increase in the meter readings during the
        time(s) selected. (Note: If the meter reading decreases during this period, this is
        ignored).
      </p>
    ),
  },
  illuminanceArb: {
    description: (
      <p>The measured light level, can be used to determine if lights have been left on.</p>
    ),
  },
  motionEvent: {
    description: (
      <p>
        Motion detection sensors placed under desks or in small rooms can be used to determine to
        usage. Motion events are measured and interpreted according to the user set thresholds (set
        on the Dashboard page) to determine if an asset is currently in use, has been used recently
        or is likely to be free.
      </p>
    ),
  },
  occSignatures: {
    description: (
      <p>
        The number of detected human signatures measured by the ceiling mounted sensor can be used
        to give a precise count of occupancy in a specific location.
      </p>
    ),
  },
  onlineStatus: {
    description: (
      <p>
        This data shows the online status of your sensors. When a sensor is online it will be able
        to send data to the platform. In general if a sensor is offline it may indicate connectivity
        or power problems, which may result in missing data and may be a cause for investigation.
      </p>
    ),
  },
  pressuremBar: {
    description: (
      <p>
        Air pressure is generally influenced by atmospheric conditions and related to weather
        conditions. In rare cases where the building is well isolated from the external environment
        changes in pressure may be related to the functioning of the building.
      </p>
    ),
  },
  particulateMatter: {
    description: (
      <p>
        Particulate matter readings give and indication as to the pollution levels in the air. Air
        pollution is a major environmental risk to health. In 2016 ambient air pollution in both
        cities and rural areas was estimated to cause 4.2 million premature deaths per year
        worldwide; this mortality is due to exposure to small particulate matter of 2.5 microns or
        less in diameter (PM2.5), which cause cardiovascular and respiratory disease, and cancers
        <sup>1</sup>. Our sensors measure the levels of PM2.5 in the indoor environment. PM levels
        are particularly influenced by external conditions such as traffic fumes and weather
        patterns and can be generally reduced with air filtrations systems. Consistently high levels
        of particulate matter indoors raises significant concern for the occupants. Overall
        mortality and mortality of cardiopulmonary diseases as well as lung cancer has been shown to
        increase by 4%, 6% and 8%, respectively, for every 10 µg/m3 increase in PM2.5 levels
        <sup>2</sup>.
      </p>
    ),
    footnotes: [
      {
        id: 1,
        url: 'https://www.who.int/news-room/fact-sheets/detail/ambient-(outdoor)-air-quality-and-health',
        text: 'WHO air pollution guidance',
      },
      {
        id: 2,
        url: 'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4740125/',
        text: 'The impact of PM2.5 on the human respiratory system',
      },
    ],
  },
  relativeHumidity: {
    description: (
      <>
        <p>
          Humidity is the moisture content of the air and can make a difference to both the comfort
          and healthiness of the environment. The range of 40% to 60% relative humidity is the most
          comfortable and provides the the most protection against respiratory infection. Values
          outside of this, contribute to the Sick Building Syndrome<sup>1</sup>, increase the risk
          of viral infection transmission<sup>2</sup> and increase absenteeism related to
          respiratory problems<sup>3</sup>.
        </p>
        <p>
          Low relative humidity (dry air) can cause dry eyes and irritation. If air pollutants are
          present there can also be skin rash, itching, headaches and mental fatigue<sup>4</sup>.
          Too low and too high humidity can both also increase severity of asthma, dermatitis &
          nonallergic rhinitis as well as dehydration and heat exhaustion. Humidifiers and
          de-humidifiers can help mitigate these effects.
        </p>
      </>
    ),
    footnotes: [
      {
        id: 1,
        url: 'https://www.hse.gov.uk/foi/internalops/ocs/300-399/oc311_2.htm',
        text: 'Sick Building Syndrome - HSE',
      },
      {
        id: 2,
        url: 'http://sterlingiaq.com/photos/1044922973.pdf',
        text: 'ASHRAE',
      },
      {
        id: 3,
        url: 'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC1474709/',
        text: 'Indirect health effects of relative humidity in indoor environments',
      },
      {
        id: 4,
        url: 'https://www.aivc.org/resource/sick-building-syndrome-symptoms-caused-low-humidity',
        text: 'Sick building syndrome symptoms caused by low humidity - Fand et al 2003',
      },
    ],
  },
  temperatureC: {
    description: (
      <p>
        Temperature will affect the comfort and performance, and specific preferences can be a
        function of the body’s metabolic rate<sup>1</sup>. CIBSE guidance is to maintain air
        temperatures are maintained between 19C - 23C (Winter) and 19C – 25C (Summer). Research
        suggests that productivity begins to decrease for temperatures below 19C and above 25C
        <sup>2</sup>. High air velocity and drafts may be a bigger factor in perceived thermal
        comfort levels.
      </p>
    ),
    footnotes: [
      {
        id: 1,
        url: 'https://journals.plos.org/plosone/article?id=10.1371/journal.pone.0216362',
        text: 'Battle for the thermostat',
      },
      {
        id: 2,
        url: 'https://www.osti.gov/servlets/purl/813396',
        text: 'Cost benefit analysis of the night-time ventilative cooling in office building',
      },
    ],
  },
  VOCppb: {
    description: (
      <p>
        Volatile organic compounds (VOCs) are a family of common contaminants in indoor air and
        include a variety of chemicals, some of which may have short- and long-term adverse health
        effects. VOCs are emitted by a wide array of products numbering in the thousands. Examples
        include: paints and lacquers, paint strippers, cleaning supplies, pesticides, building
        materials and furnishings, office equipment such as copiers and printers, correction fluids
        and carbonless copy paper, graphics and craft materials including glues and adhesives,
        permanent markers, and photographic solutions.
      </p>
    ),
    footnotes: [
      {
        id: 1,
        url: 'https://www.epa.gov/indoor-air-quality-iaq/what-are-volatile-organic-compounds-vocs',
        text: 'What are volatile organic compounds (VOCs)?',
      },
    ],
  },
  frequencyHz: {
    description: <p>Frequency in Hertz</p>,
  },
  outputLevelPct: {
    description: <p>Output level as a percentage</p>,
  },
  unknown: {
    description: <p>There appears to be a processing error.</p>,
  },
};

export default varNameDescriptions;
