/* eslint-disable import/prefer-default-export */

import { DataItem, Position, SensorLatest, VarName } from '../services/api';
import { NearBySensor } from '../state/types';

// Simple helper function to work out (shortish) distance between positions
function euclideanDistance(pos1: Position, pos2: Position): number {
  if (!pos1.lat || !pos1.lng || !pos2.lat || !pos2.lng) return Infinity;
  const R = 6371000; // Radius of the Earth in meters
  const toRadians = (degrees: number) => degrees * (Math.PI / 180);
  const dLat = toRadians(pos2.lat - pos1.lat);
  const dLng = toRadians(pos2.lng - pos1.lng);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRadians(pos1.lat)) *
      Math.cos(toRadians(pos2.lat)) *
      Math.sin(dLng / 2) *
      Math.sin(dLng / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c; // Distance in meters
}

// For a given array of sensors find the one nearest the targetPosition
export function findNearestSensor(sensors: SensorLatest[], targetPosition: Position): SensorLatest | null {
  if (sensors.length === 0) {
    return null;
  }
  let nearestSensor = null;
  let minDistance = Infinity;
  sensors.forEach((sensor) => {
    if (sensor.position && sensor.position.lat !== undefined && sensor.position.lng !== undefined) {
      const distance = euclideanDistance(sensor.position, targetPosition);
      if (distance < minDistance) {
        minDistance = distance;
        nearestSensor = sensor;
      }
    }
  });
  return nearestSensor;
}

const findPositionBySignalAndMostRecent = async (
  bleSensors: NearBySensor[] | undefined,
  sensorsInLocation: SensorLatest[] | undefined,
  appScanStatus: boolean
) => {
  // TODO Try a trilateration method
  let targetPosition: Position | undefined;
  let targetPolygon;
  const sum = { x: 0, y: 0, m: 0 };
  if (bleSensors && appScanStatus) {
    const strongestRssi = bleSensors[0]?.rssi;
    const mostRecent = [...bleSensors].sort((a, b) => b.lastSeen - a.lastSeen)[0].lastSeen;
    for (let i = 0; i < bleSensors.length; i++) {
      const { id, rssi, lastSeen } = bleSensors[i];
      const sensorPosition = sensorsInLocation?.find((sensor) => sensor.id === id)?.position;
      if (!targetPolygon) targetPolygon = sensorPosition?.polygon;
      if (sensorPosition) {
        const { lat, lng } = sensorPosition;
        if (lat && lng) {
          const signalStrength = 10 ** ((rssi - strongestRssi) / (10 * 2));
          const timeDiff = mostRecent - lastSeen;
          let lastSeenStrength;
          if (timeDiff < 10) lastSeenStrength = 1;
          else if (timeDiff < 60) lastSeenStrength = 0.5;
          else lastSeenStrength = 0.25;
          sum.x += (signalStrength + lastSeenStrength) * lat;
          sum.y += (signalStrength + lastSeenStrength) * lng;
          sum.m += signalStrength + lastSeenStrength;
        }
      }
    }
  }
  if (sum.x && sum.y && sum.m)
    targetPosition = { lat: sum.x / sum.m, lng: sum.y / sum.m, polygon: targetPolygon };
  return targetPosition ?? null;
};

export const getBleSensorTargetPosition = async (
  bleSensors: NearBySensor[] | undefined,
  sensorsInLocation: SensorLatest[] | undefined,
  appScanStatus: boolean
) => findPositionBySignalAndMostRecent(bleSensors, sensorsInLocation, appScanStatus);

// get available varName for the sensor
export const getSensorDataVars = (data: DataItem[]): VarName[] => {
  const availableVarNames: VarName[] = [];
  data.forEach((dataItem) => {
    if (!availableVarNames.includes(dataItem.varName)) availableVarNames.push(dataItem.varName);
  });

  return availableVarNames;
};
