import {
  LocationTreeItems,
  UserPublic,
  SensorLatest,
  PropLocationItem,
  Position,
} from '../services/api';
import { VarName } from '../utils/varNames';
import { ActionTypes, ActionType } from './actionTypes';
import {
  LocationFloorplanPayload,
  ActiveItem,
  SortType,
  SearchPayload,
  HoursSelection,
  TempUser,
  NearBySensor,
  ThemeMode,
  GhostParams,
  SelectedBand,
} from './types';

export const setMotionThreshold = (range: [number, number]): ActionTypes => ({
  type: ActionType.SET_MOTION_THRESHOLD,
  payload: range,
});

export const setSensorVars = (vars: Array<VarName>): ActionTypes => ({
  type: ActionType.SET_SENSOR_VARS,
  payload: vars,
});

export const setTempUser = (tempUser: TempUser | null): ActionTypes => ({
  type: ActionType.SET_TEMP_USER,
  payload: tempUser,
});

export const setUserPublic = (userDetails: UserPublic | null): ActionTypes => ({
  type: ActionType.SET_USER_DETAILS,
  payload: userDetails,
});

export const setUserPosition = (position: Position | null): ActionTypes => ({
  type: ActionType.SET_USER_POSITION,
  payload: position,
});

export const setUserLocation = (location: string): ActionTypes => ({
  type: ActionType.SET_USER_LOCATION,
  payload: location,
});

export const toggleShowSensorLabels = (): ActionTypes => ({
  type: ActionType.TOGGLE_SHOW_SENSOR_LABELS,
});

export const setBleLocSwitchStatus = (status: boolean): ActionTypes => ({
  type: ActionType.ALLOW_BLE_LOC_SWITCH,
  payload: status,
});

export const setBleSensors = (sensors: NearBySensor[]): ActionTypes => ({
  type: ActionType.SET_BLE_SENSORS,
  payload: sensors,
});

export const setBleWebViewUrl = (url: string): ActionTypes => ({
  type: ActionType.SET_BLE_WEBVIEW_URL,
  payload: url,
});

export const setBleScanStatus = (status: boolean): ActionTypes => ({
  type: ActionType.SET_BLE_SCAN_STATUS,
  payload: status,
});

// prefix 'go' for action for API service fetchAllLatestVarname
export const goFetchAllLatestVarName = (varName: VarName): ActionTypes => ({
  type: ActionType.GO_FETCH_ALL_LATEST_VARNAME,
  payload: varName,
});

export const goFetchLocationFloorplan = (locationId: string): ActionTypes => ({
  type: ActionType.GO_FETCH_LOCATION_FLOORPLAN,
  payload: locationId,
});

export const goFetchSensorDetails = (sensorId: string): ActionTypes => ({
  type: ActionType.GO_FETCH_SENSOR_DETAILS,
  payload: sensorId,
});

export const setSensorsById = (sensorDetails: SensorLatest[]): ActionTypes => ({
  type: ActionType.SET_SENSORS_BY_ID,
  payload: sensorDetails,
});

export const removeSensorData = (sensorId: string): ActionTypes => ({
  type: ActionType.REMOVE_SENSOR_DATA,
  payload: sensorId,
});

export const setLocationData = (locationData: LocationTreeItems | null): ActionTypes => ({
  type: ActionType.SET_LOCATION_DATA,
  payload: locationData,
});

export const updateLocationData = (newDetails: PropLocationItem): ActionTypes => ({
  type: ActionType.UPDATE_LOCATION_DATA,
  payload: newDetails,
});

export const removeLocationData = (locationId: string): ActionTypes => ({
  type: ActionType.REMOVE_LOCATION_DATA,
  payload: locationId,
});

export const setCurrentLocation = (location: string): ActionTypes => ({
  type: ActionType.SET_CURRENT_LOCATION,
  payload: location,
});

export const setSelectedSensors = (selectedSensorIds: string[]): ActionTypes => ({
  type: ActionType.SET_SELECTED_SENSORS,
  payload: selectedSensorIds,
});

export const setLocationDirectChildren = (locations: PropLocationItem[]): ActionTypes => ({
  type: ActionType.SET_CURRENT_LOCATION_CHILDREN,
  payload: locations,
});

export const setLocationFloorplan = (
  locationIdAndFloorplan: LocationFloorplanPayload
): ActionTypes => ({
  type: ActionType.SET_LOCATION_FLOORPLAN,
  payload: locationIdAndFloorplan,
});

export const goFetchRefresh = (): ActionTypes => ({
  type: ActionType.GO_FETCH_REFRESH,
});

export const setSelectedStartDate = (selectedDate: Date): ActionTypes => ({
  type: ActionType.SET_SELECTED_START_DATE,
  payload: selectedDate,
});

export const setSelectedEndDate = (selectedDate: Date): ActionTypes => ({
  type: ActionType.SET_SELECTED_END_DATE,
  payload: selectedDate,
});

export const setHighlightedItem = (item: ActiveItem): ActionTypes => ({
  type: ActionType.SET_HIGHLIGHTED_ITEM,
  payload: item,
});

export const setActiveMarker = (varName: VarName): ActionTypes => ({
  type: ActionType.SET_ACTIVE_MARKER,
  payload: varName,
});

export const setDashboardPanel = (varName: VarName): ActionTypes => ({
  type: ActionType.SET_DASHBOARD_PANEL,
  payload: varName,
});

export const setClickedItem = (item: ActiveItem): ActionTypes => ({
  type: ActionType.SET_CLICKED_ITEM,
  payload: item,
});

export const toggleDashboardPanel = (varName: VarName): ActionTypes => ({
  type: ActionType.TOGGLE_DASHBOARD_PANEL,
  payload: varName,
});

export const toggleActivePlotVar = (varName: VarName): ActionTypes => ({
  type: ActionType.TOGGLE_ACTIVE_PLOT_VAR,
  payload: varName,
});

export const showHelp = (show: boolean): ActionTypes => ({
  type: ActionType.SHOW_HELP,
  payload: show,
});

export const setSearchTerm = (search: SearchPayload): ActionTypes => ({
  type: ActionType.SET_SEARCH_TERM,
  payload: search,
});

export const setSortBy = (sortBy: SortType): ActionTypes => ({
  type: ActionType.SET_SORT_BY,
  payload: sortBy,
});

export const setSelectedHours = (selectedHours: HoursSelection): ActionTypes => ({
  type: ActionType.SET_SELECTED_HOURS,
  payload: selectedHours,
});

export const setShowNavDrawer = (showNavDrawer: boolean): ActionTypes => ({
  type: ActionType.SET_SHOW_NAV_DRAWER,
  payload: showNavDrawer,
});

export const setThemeMode = (mode: ThemeMode): ActionTypes => ({
  type: ActionType.SET_THEME_MODE,
  payload: mode,
});

export const setGhostParams = (params: GhostParams): ActionTypes => ({
  type: ActionType.SET_GHOST_PARAMS,
  payload: params,
});

export const setStackedTraces = (showStackedTraces: boolean): ActionTypes => ({
  type: ActionType.SET_STACKED_TRACES,
  payload: showStackedTraces,
});

export const setPlotGrid = (showPlotGrid: boolean): ActionTypes => ({
  type: ActionType.SET_PLOT_GRID,
  payload: showPlotGrid,
});

export const setPlotLegend = (showPlotLegend: boolean): ActionTypes => ({
  type: ActionType.SET_PLOT_LEGEND,
  payload: showPlotLegend,
});

export const setSelectedBand = (selectedBand: SelectedBand | null): ActionTypes => ({
  type: ActionType.SET_SELECTED_BAND,
  payload: selectedBand,
});

export const setRefreshTimeStamp = (timeStamp: {
  varName: VarName;
  time: number;
}): ActionTypes => ({
  type: ActionType.SET_REFRESH_TIMESTAMP,
  payload: timeStamp,
});

export const toggleShowAdvancedAnalysis = (show: boolean): ActionTypes => ({
  type: ActionType.TOGGLE_SHOW_ADVANCED_ANALYSIS,
  payload: show,
});

export const setAvailableVars = (vars: VarName[]): ActionTypes => ({
  type: ActionType.SET_AVAILABLE_VARS,
  payload: vars,
});

export const clearReduxStore = (): ActionTypes => ({
  type: ActionType.CLEAR_REDUX_STORE,
});
