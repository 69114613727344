import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => ({
  dashboardCard: {
    background: theme.palette.primary.main,
    color: theme.palette.text.primary,
    height: '250px',
    padding: '10px',
  },

  dashboardRingPlot: {
    width: '200px',
    height: '200px',
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
  },

  pillBandLabel: {
    padding: '3px 12px',
    borderRadius: '70px',
  },
  floatingContainer: {
    position: 'absolute',
    top: '68px',
    left: '10px',
    zIndex: 1000,
    overflowY: 'scroll',
    height: '100%',
    width: 800,
    paddingBottom: '50px',
    [theme.breakpoints.down('xl')]: {
      width: '50%',
    },
    [theme.breakpoints.down('lg')]: {
      width: '100%',
      position: 'unset',
      marginBottom: ' 10px',
      height: 'auto',
      overflowY: 'unset',
    },
  },

  floatingBox: {
    width: '100%',
    boxShadow: '0 1px 6px 1px #00000091',
    backgroundColor: theme.palette.primary.main,
    borderRadius: '8px',
    [theme.breakpoints.down('lg')]: {
      height: 'auto',
    },
  },
  floatingHeader: {
    width: '100%',
    position: 'absolute',
    top: '0',
    left: '0',
    zIndex: 1000,
    background: 'none',
    padding: '10px',
    [theme.breakpoints.down('lg')]: {
      position: 'unset',
      padding: '0 0 10px 0',
    },
  },
}));

export default useStyles;
