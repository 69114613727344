import React, { useState, useEffect, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSelector, useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Typography from '@mui/material/Typography';

import IconButton from '@mui/material/IconButton';
import useGlobalStyles from '../../styles';
import useStyles from '../../styles/dashboard';
import MapGL from '../Map/Map';
import Header from '../../components/Header';
import { getActiveMarker, getCurrentLocation } from '../../state/selectors';
import SensorIcon from '../../styles/icons/SensorIcon';
import { setClickedItem, setSelectedBand } from '../../state/actions';
import SensorsList from './SensorsList';
import MapViewIcon from '../../styles/icons/MapViewIcon';
import { MapCentrePosition } from '../Map/mapHelpers';
import { varNameDetails } from '../../utils/varNames';
import SelectedVarsIconBtn from '../../components/SelectedVarsIconBtn';
import SelectedSensorPlot from './SelectedSensorPlot';

function ActiveSource(): JSX.Element {
  const theme = useTheme();
  const globalClasses = useGlobalStyles();
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const checkFirstRender = useRef(true);
  const activeMarker = useSelector(getActiveMarker);
  const currentLocation = useSelector(getCurrentLocation);
  const isDesktopView = useMediaQuery(useTheme().breakpoints.up('lg'));

  const floatingElementRef = useRef(null);
  const [showMap, setShowMap] = useState(false);

  const ActiveMarkerIcon = varNameDetails[activeMarker]?.icon ?? SensorIcon;

  // reset clicked item on location or active marker change
  useEffect(() => {
    // avoid reset on first render where sensor might have been clicked to land here
    if (checkFirstRender.current) {
      checkFirstRender.current = false;
    } else {
      dispatch(setClickedItem({ id: '' }));
    }
  }, [currentLocation, activeMarker, dispatch]);

  const FloatingIcon = showMap ? SensorIcon : MapViewIcon;

  const showMapContent = () => {
    let mapContent;
    if (isDesktopView)
      mapContent = <MapGL showSourceToolbar={false} mapCentrePosition={MapCentrePosition.right} />;
    // for small screen show map only when map button is clicked
    if (showMap) mapContent = <MapGL />;
    return mapContent;
  };

  const handleBackBtn = () => {
    navigate('/');
    dispatch(setSelectedBand(null));
  };

  return (
    <div
      style={{ overflowY: 'hidden', height: '100%', padding: '0' }}
      className={globalClasses.bodyContent}
    >
      <Box
        sx={{
          position: 'relative',
          height: '100%',
          padding: { xs: '10px', lg: 0 },
        }}
      >
        <Box className={classes.floatingHeader}>
          <Header showSearchField={false} />
        </Box>
        {/* selected sensor plot floating container */}
        <Box
          ref={floatingElementRef}
          sx={{ display: { lg: 'block', xs: showMap ? 'none' : 'block' } }}
          className={classes.floatingContainer}
        >
          <div className={classes.floatingBox}>
            <Box
              sx={{
                display: { lg: 'flex', xs: 'block' },
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '8px',
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <IconButton
                  onClick={handleBackBtn}
                  style={{ background: theme.palette.primary.light, height: 'fit-content' }}
                >
                  <ArrowBackIcon />
                </IconButton>
                <Box sx={{ marginLeft: '5px' }}>
                  <ActiveMarkerIcon />
                </Box>
                <Typography variant="h5" style={{ marginLeft: '10px' }}>
                  {varNameDetails[activeMarker].label}
                </Typography>
              </div>
              <SelectedVarsIconBtn />
            </Box>
            <Box sx={{ paddingBottom: '2px', marginTop: '-16px' }}>
              <SelectedSensorPlot />
            </Box>
          </div>
          {/* Sensors list floating container */}
          <SensorsList floatingElementRef={floatingElementRef} />
        </Box>
        {showMapContent()}
        <Fab
          color="error"
          size="medium"
          className={`${globalClasses.floatingBtn}`}
          style={{ bottom: '20px' }}
          sx={{ display: { lg: 'none' } }}
        >
          <FloatingIcon onClick={() => setShowMap(!showMap)} />
        </Fab>
      </Box>
    </div>
  );
}

export default ActiveSource;
