import React, { useEffect, useState } from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import LocationOnIcon from '@mui/icons-material/LocationOn';

import { useDispatch, useSelector } from 'react-redux';
import {
  getCurrentLocation,
  getCurrentLocationChildSensors,
  getCurrentLocationChildLocations,
  getLocationsById,
  getLocationIdsByParentId,
} from '../state/selectors';
import LocationBreadcrumb, { LocationBreadcrumbItem } from './LocationBreadcrumb';
import { setCurrentLocation } from '../state/actions';
import { persistState, StorageTypes } from '../utils/persistentState';
import Help from './Help';
import useStyles from '../styles/index';
import { PropLocationItem } from '../services/api';

interface LocationSwitcherProps {
  autoSwitchLoc?: boolean;
}

function LocationSwitcher({ autoSwitchLoc }: LocationSwitcherProps): JSX.Element {
  const theme = useTheme();
  const currentLocationId = useSelector(getCurrentLocation);
  const currentLocationChildLocations = useSelector(getCurrentLocationChildLocations);
  const locationsById = useSelector(getLocationsById);
  const locationsByParent = useSelector(getLocationIdsByParentId);
  const locSensors = useSelector(getCurrentLocationChildSensors);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [breadcrumbs, setBreadcrumbs] = useState<LocationBreadcrumbItem[]>([]);
  const isDesktopView = useMediaQuery(theme.breakpoints.up('md'));

  useEffect(() => {
    const breadcrumbNew: LocationBreadcrumbItem[] = [];
    if (currentLocationChildLocations.length > 0) {
      breadcrumbNew.push({ location: null, siblings: currentLocationChildLocations });
    }
    let currentId = currentLocationId;
    while (currentId !== '' && currentId !== 'None') {
      const currentLocation = locationsById.get(currentId);
      if (!currentLocation) {
        // throw new Error(`Location with id ${currentId} not found`);
        return;
      }
      const parentId = currentLocation.location ?? '';
      // Note this includes the current location in siblings
      const siblingIds = locationsByParent.get(parentId ?? '') || [];
      const siblings = siblingIds
        .map((id) => locationsById.get(id))
        .filter((location) => location !== undefined) as PropLocationItem[];
      breadcrumbNew.unshift({
        location: currentLocation,
        siblings,
      });
      currentId = parentId; // Move to the parent location
    }

    setBreadcrumbs(breadcrumbNew);
  }, [currentLocationId, locationsById, locationsByParent, currentLocationChildLocations]);

  useEffect(() => {
    if (autoSwitchLoc) {
      // Use currentLocationChildLocations to determine if we can autoselect next location if there is only one subLocation
      if (currentLocationChildLocations.length === 1) {
        if (currentLocationId === currentLocationChildLocations[0].location) {
          if (locSensors.length === 0) {
            persistState(currentLocationChildLocations[0].id, StorageTypes.CurrentLocation);
            dispatch(setCurrentLocation(currentLocationChildLocations[0].id));
          }
        }
      }
    }
  }, [autoSwitchLoc, currentLocationId, currentLocationChildLocations, dispatch, locSensors]);

  return (
    <Help
      helpText="Change the location you are viewing"
      position={isDesktopView ? 'right' : 'bottom'}
    >
      <Box className={classes.breadCrumbMenu} sx={{ display: 'flex' }}>
        <LocationOnIcon sx={{ alignSelf: 'center', marginLeft: '5px'}} />
        <Breadcrumbs separator="›" aria-label="breadcrumb" color="textPrimary">
          {breadcrumbs.map(({ location, siblings }: LocationBreadcrumbItem) => (
            <LocationBreadcrumb
              key={`location-crumb-${location?.id ?? 'NEXTITEMS'}`}
              location={location}
              siblings={siblings}
              shortName={!isDesktopView}
            />
          ))}
        </Breadcrumbs>
      </Box>
    </Help>
  );
}

LocationSwitcher.defaultProps = {
  autoSwitchLoc: true,
};

export default LocationSwitcher;
