import React, { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';
// eslint-disable-next-line import/no-unresolved
import '@aws-amplify/ui-react/styles.css';
import { useDispatch, useSelector } from 'react-redux';
import posthog from 'posthog-js';

import Shell from './Shell';
import { AuthState } from '../services/authenticationService';
import useStyles from '../styles';
import {
  getActiveMarker,
  getTempUser,
  getThemeMode,
  getBleSensors,
  getSensorsById,
  getCurrentLocationChildSensors,
  getUserLocation,
  getCurrentLocation,
} from '../state/selectors';
import {
  setTempUser,
  setActiveMarker,
  setBleSensors,
  setBleWebViewUrl,
  setBleScanStatus,
  setUserPosition,
  setUserLocation,
} from '../state/actions';
import { Logo } from '../components/Logo';
import './amplify.css';
import { fetchSensorLatest, fetchTempUserToken } from '../services/apiService';
import { TempUser, ThemeMode } from '../state/types';
import { persistState, StorageTypes } from '../utils/persistentState';
import {
  loginComponents,
  formFields,
  parseJwt,
  tempUserExpiryStr,
  handleLogout,
  appSwitchBtnHandler,
} from './helpers';
import { VarName } from '../utils/varNames';
import MobileAppIcon from '../styles/icons/MobileAppIcon';
import UserPolicyDialog from '../Widgets/Dialog/UserPolicyDialog';
import { getBleSensorTargetPosition } from '../utils/sensors';

interface AuthShellProps {
  setThemeType: React.Dispatch<React.SetStateAction<ThemeMode>>;
}

function AuthShell({ setThemeType }: AuthShellProps): JSX.Element {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { route } = useAuthenticator((context) => [context.route]);
  const { user } = useAuthenticator((context) => [context.user]);
  const [bleActiveMarker, setBleActiveMarker] = useState<VarName>();
  const [showUserPolicy, setShowUserPolicy] = useState(false);
  const [bleDeviceId, setBleDeviceId] = useState('');
  const [bleScanTimeStamp, setBleScanTimeStamp] = useState(dayjs());
  const [bleScannedSensors, setBleScannedSensors] = useState([]);

  useEffect(() => {
    //  show user policy if user has logged in for the first time
    if (route === AuthState.ForceNewPassword) setShowUserPolicy(true);
  }, [route]);

  // Extract any temporary credential from URL
  const params = new URLSearchParams(window.location.search);
  const tempCredential = params.get('credential');
  const tempUser: TempUser | null = useSelector(getTempUser);
  const bleSensors = useSelector(getBleSensors);
  const sensorsById = useSelector(getSensorsById);
  const activeMarker = useSelector(getActiveMarker);
  const userLocation = useSelector(getUserLocation);
  const currentLocation = useSelector(getCurrentLocation);
  const locSensors = useSelector(getCurrentLocationChildSensors);

  const themeMode = useSelector(getThemeMode);
  const hasUser = user?.attributes?.email !== undefined;

  const userName = tempUser?.expiry
    ? tempUserExpiryStr(tempUser.expiry)
    : user?.attributes?.email ?? 'Unknown';

  // this disables tracking for localhost
  if (window.location.href.includes('localhost')) {
    // if tracking is needed posthog.opt_in_capturing() should be called
    posthog.opt_out_capturing();
  }

  useEffect(() => setThemeType(themeMode), [setThemeType, themeMode]);

  useEffect(() => {
    let userInfo;
    if (hasUser) userInfo = { userId: user?.attributes?.email ?? 'Unknown', userType: 'Full' };
    else if (tempUser) userInfo = { userId: tempUser.credential, userType: 'Temporary' };
    if (userInfo) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.ReactNativeWebView?.postMessage(JSON.stringify({ userInfo }));
    }
  }, [hasUser, tempUser, user]);

  // Try to obtain accessToken for tempCredential
  useEffect(() => {
    if (tempCredential && !hasUser) {
      if (!tempUser || tempUser.credential !== tempCredential) {
        fetchTempUserToken(tempCredential)
          .then((token) => {
            if (token?.access_token) {
              persistState('#', StorageTypes.CurrentLocation);
              const tokenDecoded = parseJwt(token.access_token);
              const tempUserNew: TempUser = {
                credential: tempCredential,
                accessToken: token.access_token,
                expiry: tokenDecoded.exp ?? 0,
              };
              // TODO work out if tempUser is sub-access of current full user
              dispatch(setTempUser(tempUserNew));
            }
          })
          .catch(() => {
            dispatch(setTempUser(null));
          });
      }
    } else if (hasUser) {
      dispatch(setTempUser(null));
    }
  }, [tempCredential, dispatch, hasUser, tempUser]);

  // Auto logout tempUser at expiry
  useEffect(() => {
    if (tempUser?.expiry) {
      setTimeout(() => handleLogout(tempUser, dispatch), tempUser.expiry * 1000 - Date.now());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tempUser]);

  // improve app effeciency by reducing amount of bleSensors message
  useEffect(() => {
    if (
      dayjs().diff(bleScanTimeStamp, 'second') >= 2 ||
      (bleScannedSensors.length > 0 && bleScannedSensors.length !== bleSensors?.length)
    ) {
      dispatch(setBleSensors(bleScannedSensors));
      setBleScanTimeStamp(dayjs());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bleScanTimeStamp, bleScannedSensors]);

  // listener for mobile app messages
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const messageHandler = (event: MessageEvent<any>) => {
      if (event.data.deviceId) setBleDeviceId(event.data.deviceId);
      if (event.data.bleSensors) setBleScannedSensors(event.data.bleSensors);
      // to keep the portal as single page application, use url to navigate
      if (event.data.bleWebViewUrl) dispatch(setBleWebViewUrl(event.data.bleWebViewUrl));
      if (event.data.activeMarker) setBleActiveMarker(event.data.activeMarker);
      if (event.data.bleScanStatus !== undefined)
        dispatch(setBleScanStatus(event.data.bleScanStatus));
    };

    window.addEventListener('message', messageHandler);

    return () => window.removeEventListener('message', messageHandler);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // pass all sensor names to mobile app
    if (hasUser && sensorsById.size > 0) {
      const sensorNames: { id: string; name: string }[] = [];
      sensorsById.forEach((values, keys) => {
        sensorNames.push({ id: keys, name: values.name ?? values.id });
      });
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.ReactNativeWebView?.postMessage(JSON.stringify({ sensorNames }));
    }
  }, [sensorsById, hasUser]);

  // set active marker selected from mobile app
  useEffect(() => {
    if (bleActiveMarker) dispatch(setActiveMarker(bleActiveMarker));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bleActiveMarker]);

  // pass active marker if changed in portal side for consistency
  useEffect(() => {
    const whiteList: VarName[] = [
      VarName.Co2ppm,
      VarName.TemperatureC,
      VarName.RelativeHumidity,
      VarName.ParticulateMatter,
    ];

    if (whiteList.includes(activeMarker) && bleActiveMarker !== activeMarker)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.ReactNativeWebView?.postMessage(JSON.stringify({ activeMarker }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeMarker]);

  useEffect(() => {
    if (bleDeviceId) {
      if (posthog.get_distinct_id() !== bleDeviceId) {
        posthog.identify(bleDeviceId, { userType: 'mobile app user' });
      }
    } else if (tempUser?.credential) posthog.people.set({ userType: 'temporary' });
  }, [bleDeviceId, tempUser?.credential]);

  useEffect(() => {
    if (route === AuthState.Authenticated && user.attributes?.email !== undefined) {
      posthog.identify(
        user.username, // distinct_id, required
        { email: user.attributes.email, userType: 'full' } // $set_once, optional
      );
    }
  }, [route, user]);

  const showLoginForm = useMemo(() => {
    let showForm = false;
    if (!tempUser && !tempCredential && !(route === AuthState.Authenticated && hasUser)) {
      showForm = true;
    } else if (tempCredential) {
      if (!tempUser && route === AuthState.SetUp) showForm = true;
    }
    return showForm;
  }, [tempUser, tempCredential, route, hasUser]);

  const getLocation = (id: string) => {
    let location = '';
    fetchSensorLatest(id).then((res) => {
      if (res.location) location = res.location;
    });
    return location;
  };

  // detect user location based on nearby sensors
  useEffect(() => {
    if (bleSensors) {
      for (let i = 0; i < bleSensors.length; i++) {
        const sensorLocation =
          sensorsById.get(bleSensors[i].id)?.location ?? getLocation(bleSensors[i].id);
        if (sensorLocation) {
          if (sensorLocation !== userLocation) dispatch(setUserLocation(sensorLocation));
          break;
        }
      }

      // set user position if selected location is user location
      if (userLocation === currentLocation) {
        getBleSensorTargetPosition(bleSensors, locSensors, true).then((sensorPosition) => {
          if (sensorPosition) {
            dispatch(setUserPosition(sensorPosition));
          }
        });
      } else dispatch(setUserPosition(null));
    }
  }, [bleSensors, currentLocation, dispatch, locSensors, sensorsById, userLocation]);

  return !showLoginForm ? (
    <>
      {showUserPolicy && (
        <UserPolicyDialog showUserPolicy closeDialog={() => setShowUserPolicy(false)} />
      )}
      <Shell userName={userName} />
      {/* Cookie banner for future use
        {posthog.has_opted_in_capturing() ? null : <PosthogBanner />}
      */}
    </>
  ) : (
    <main>
      <AppBar position="relative" className={classes.header}>
        <Toolbar>
          <Logo />
        </Toolbar>
      </AppBar>
      <div className={classes.amplifyContainer}>
        <Authenticator hideSignUp components={loginComponents} formFields={formFields} />
        {/* Check from ble sensors if the view is mobileApp */}
        {bleScannedSensors.length > 0 && (
          <Button
            color="secondary"
            variant="contained"
            onClick={() => appSwitchBtnHandler(dispatch)}
            style={{ width: '70%', margin: '20px auto' }}
            startIcon={<MobileAppIcon style={{ fontSize: '32px' }} />}
          >
            NearBy Sensors
          </Button>
        )}
      </div>
    </main>
  );
}

export default AuthShell;
