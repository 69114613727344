import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import ButtonTooltip from '@mui/material/Tooltip';
import ExploreIcon from '@mui/icons-material/Explore';
import ExploreOffIcon from '@mui/icons-material/ExploreOff';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import AbcIcon from '@mui/icons-material/Abc';
import useStyles from '../../styles';
import { themeProps } from '../../styles/theme';
import { setBleLocSwitchStatus, toggleShowSensorLabels } from '../../state/actions';
import { getBleLocSwitchStatus, getUserPosition } from '../../state/selectors';

interface MapToolbarProps {
  fitToView?: (forceUserPos?: boolean) => void;
  resetOrientation?: () => void;
  pitch: number;
  bearing: number;
}

function MapToolbar({ fitToView, resetOrientation, pitch, bearing }: MapToolbarProps): JSX.Element {
  const classes = useStyles();
  const dispatch = useDispatch();
  const allowBleLocSwitch = useSelector(getBleLocSwitchStatus);
  const userPosition = useSelector(getUserPosition);
  const canGoAutoPos = !allowBleLocSwitch && userPosition;

  const handleAutoLocation = () => {
    // Return to app autoLocation mode
    dispatch(setBleLocSwitchStatus(true));
    // call fitToView, force it to use userPosition if available
    if (fitToView) fitToView(true);
  };

  return (
    <div className={` ${classes.mapToolbar}`}>
      {fitToView && (
        <ButtonTooltip title="Go to location" placement="left">
          <IconButton onClick={handleAutoLocation} color="inherit">
            {canGoAutoPos ? (
              <MyLocationIcon fontSize="large" sx={{ color: themeProps.colors.warningRed }} />
            ) : (
              <MyLocationIcon />
            )}
          </IconButton>
        </ButtonTooltip>
      )}
      {resetOrientation && (
        <ButtonTooltip title="Reset orientation" placement="left">
          <IconButton onClick={resetOrientation} color="inherit">
            {pitch !== 0 || bearing !== 0 ? (
              /* eslint-disable indent */
              <ExploreOffIcon />
            ) : (
              <ExploreIcon />
            )}
            {/* eslint-enable indent */}
          </IconButton>
        </ButtonTooltip>
      )}
      <ButtonTooltip title="Show Sensor labels" placement="left">
        <IconButton onClick={() => dispatch(toggleShowSensorLabels())} color="inherit">
          <AbcIcon />
        </IconButton>
      </ButtonTooltip>
    </div>
  );
}

MapToolbar.defaultProps = {
  fitToView: undefined,
  resetOrientation: undefined,
};

export default MapToolbar;
